import React from "react"
import { useParams} from "react-router";

const FAQ = () => {
    let params = useParams();
    /*let location = useLocation();
    let history = useHistory();*/

    return (
        <div>
            In React Router v5, You can use hooks to get parameters.
            <br />
            Current id parameter is <strong>{params.id}</strong>
            <br />
        </div>
    );
}

export {FAQ}