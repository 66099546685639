import React, {useMemo, useState} from "react"
import {Route, Switch} from "react-router-dom"
import {SingleTenderDetails2} from "./components/singleTenderDetails2";
import {FAQ} from "./components/faq";
import {NotFound} from "./components/NotFound";
import {Sidebar} from "./components/sidebar";
import IndexPage from "./components/indexPage";
import {Homepage2} from "./components/homepage2";
import {CategoryContext} from "./components/CategoryContext";

const Routes = () => {
    let [category, setCategory] = useState("hello")
    // const providerValue = useMemo(() => ({category, setCategory}), [category, setCategory])

    return (
        <div>
            <div className="container-fluid">
                <div className="wrapper">
                    <Sidebar/>
                    <Switch>
                        <Route exact path="/" component={Homepage2}/>
                        {/*<CategoryContext.Provider value= {category} >*/}
                        <Route exact path="/tenders/:categoryId" component={Homepage2}/>
                        {/*</CategoryContext.Provider>*/}
                        <Route exact path="/bid/:id" component={SingleTenderDetails2}/>
                        <Route exact path="/faq/:id" component={FAQ}/>
                        <Route exact path="/index" component={IndexPage}/>
                        <Route exact component={NotFound}/>
                    </Switch>
                </div>
            </div>
        </div>
    )
};

export default Routes