import React from "react"

const Footer2 = () => {
    return (
        <div className="col-xl-4 sidebar ftco-animate bg-light pt-5">
            <div className="fitlfit-footer text-center">

                <h1 id="fitlfit-logo" className="mb-4">
                    <a href="/">
                        <img src="/logo.png" width="300" height="300" align="right" alt="Fit Le Fit Logo"/> <br />
                        Fit<span className="fitlfit-le-color">Le</span>Fit
                    </a>
                </h1>
            </div>

            <div className="sidebar-box ftco-animate">
                <h3 className="sidebar-heading">FitLeFit</h3>
                <p>FitLeFit Tender provides the latest tenders, bids and business opportunities from all major sources
                    in Ethiopia. </p>
                <p>We deliver the latest tenders from all newspapers and online resources in Addis Ababa, Afar, Amhara,
                    Beneshangul Gumuz, Dire Dawa, Gambela, Hareri, Oromia, Sidama, SNNPR, Somali and Tigray.</p>
                <p>Fitlefit provides the latest tenders in Ethiopia from multiple sources including Ethiopian Reporter
                    English, Ethiopian Reporter Amharic, Addis Zemen, Ethiopian Herald, Reporter, The Daily Monitor,
                    Fortune, Bekur, Capital, 2merkato, The Reporter, ethiotelecom, Addis Lessan, Yedebub Nigat,
                    Addis Admass, Kallacha Oromiyaa, Mekalih Tigray, and EthioTender.com </p>
            </div>

            <div className="fitlfit-footer">
                <p className="pfooter"/>
                &copy;
                2020 | Made with <i className="icon-heart" aria-hidden="true"></i> by <a
                href="https://fitlefit.com">FitLeFit</a>
            </div>

        </div>

    )
}

export {Footer2}