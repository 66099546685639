import React, {useEffect, useState} from "react"
import axios from "axios";
import {Link} from "react-router-dom";
import {useParams} from "react-router";

const tendersAPI = axios.create({
    baseURL: process.env.REACT_APP_TENDER_API,
    headers: {},
});


export const TenderHighlights2 = () => {

    let [tenders, setTenders] = React.useState([]);
    let [loading, setLoading] = React.useState(false);
    let [pageNumber, setPageNumber] = useState(1);
    let [tendersCount, setTendersCount] = useState(0);
    let {categoryId} = useParams();

    const onLoadmore = () => {
        setPageNumber(++pageNumber);
        getTenderHighlights(pageNumber, true)
    };

    const getTenderHighlights = (pn, isLoadMore) => {
        setLoading(true);
        if (!categoryId) {
            categoryId = "ethiopia-tender-all-tenders";
        }
        tendersAPI.get(categoryId + "/" + pn)
            .then(
                result => {
                    if (isLoadMore) {
                        setTenders([...tenders, ...result.data])
                    } else {
                        setTenders(result.data)
                    }
                    setLoading(false);
                    setTendersCount(result.data.length)
                }
            ).catch(error => {
            console.log(error)
        });
    };

    useEffect(() => {
        setPageNumber(1);
        getTenderHighlights(1, false)
    }, [categoryId]);
    return (
        <>
            {loading ?
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div>
                    {
                        tenders.map(
                            (tender, index) => {
                                return (
                                    <TenderHighlight key={index} tender={tender}/>
                                )
                            }
                        )
                    }
                </div>
            }
            {
                tendersCount >= 10 &&
                <div className="col-12 text-center mt-4">
                    <button onClick={onLoadmore} className="btn btn-common">Load more tenders</button>
                </div>
            }
            {
                tendersCount == 0 &&
                <div className="col-12 text-center mt-4">
                    <button className="btn btn-common">There aren't active tenders for this category as of today</button>
                </div>
            }


        </>
    );
};

const TenderHighlight = ({tender}) => {

    if (!tender) return <div/>;
    return (
        <div className="col-md-12">
            <div className="blog-entry ftco-animate d-md-flex">
                <div className="text-2 pl-md-4">
                    <h3 className="mb-2">
                        <Link to={{pathname: "/bid/" + tender.id}}>
                            {tender.tenderTitle}</Link></h3>
                    <div className="meta-wrap">
                        <p className="meta">
                            <span><i className="icon-calendar mr-2"></i>{tender.bidClosingDate}</span>
                            <span className="read-more">
                                    {/*<a href="#" className="btn-custom">*/}
                                <Link to={{pathname: "/bid/" + tender.id}}>Read More <span
                                    className="ion-ios-arrow-forward"></span></Link>
                                {/*</a>*/}
                                </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TenderHighlights2