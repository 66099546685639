import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_TENDER_API,
    headers: {
        // 'content-type':'application/octet-stream',
        // 'x-rapidapi-host':'example.com',
        // 'x-rapidapi-key': process.env.RAPIDAPI_KEY
    },
});
export default {
    getData: () =>
        instance({
            'method':'GET',
            'url':'/all',
            'params': {
                // 'search':'parameter',
            },
            transformResponse: [function (data) {

                return data.data;
            }],
        }),
    postData: () =>
        instance({
            'method': 'POST',
            'url':'/api',
            'data': {
                'item1':'data1',
                'item2':'item2'
            },
            'headers': { 'content-type':'application/json' // override instance defaults
            },
        })
}