const categoryData = [
    {
        "title": "All Tenders",
        "key": "0",
        "tooltip": "ethiopia-tender-all-tenders"
    },
    {
        "title": "Accounting & Auditing, Accounting System Design",
        "key": "93",
        "tooltip": "ethiopia-tender-accounting-auditing-accounting-system-design"
    },
    {
        "title": "Advertising & Promotion",
        "key": "122",
        "tooltip": "ethiopia-tender-advertising-promotion",
        "isFolder": true,
        "children": [
            {
                "title": "Billboards & Digital Advertising",
                "key": "45",
                "tooltip": "ethiopia-tender-billboards-digital-advertising"
            },
            {
                "title": "Event Organizing & Planning",
                "key": "37",
                "tooltip": "ethiopia-tender-event-organizing-planning"
            },
            {
                "title": "Graphic Design",
                "key": "44",
                "tooltip": "ethiopia-tender-graphic-design"
            },
            {
                "title": "Printed Advertising Materials",
                "key": "47",
                "tooltip": "ethiopia-tender-printed-advertising-materials"
            },
            {
                "title": "Promotional Items",
                "key": "39",
                "tooltip": "ethiopia-tender-promotional-items"
            },
            {
                "title": "Public Address Systems",
                "key": "123",
                "tooltip": "ethiopia-tender-public-address-systems"
            },
            {
                "title": "Public Relations",
                "key": "38",
                "tooltip": "ethiopia-tender-public-relations"
            }
        ]
    },
    {
        "title": "Agriculture & Farming",
        "key": "124",
        "tooltip": "ethiopia-tender-agriculture-farming",
        "isFolder": true,
        "children": [
            {
                "title": "Animal Feed",
                "key": "127",
                "tooltip": "ethiopia-tender-animal-feed"
            },
            {
                "title": "Flora & Horticulture",
                "key": "126",
                "tooltip": "ethiopia-tender-flora-horticulture"
            },
            {
                "title": "Live Animals",
                "key": "1",
                "tooltip": "ethiopia-tender-live-animals"
            },
            {
                "title": "Machinery & Equipment",
                "key": "46",
                "tooltip": "ethiopia-tender-machinery-equipment"
            },
            {
                "title": "Poultry, Bee & Animal Husbandry",
                "key": "128",
                "tooltip": "ethiopia-tender-poultry-bee-animal-husbandry"
            },
            {
                "title": "Products & Services",
                "key": "94",
                "tooltip": "ethiopia-tender-products-services"
            },
            {
                "title": "Raw Materials & Supplies",
                "key": "125",
                "tooltip": "ethiopia-tender-raw-materials-supplies"
            }
        ]
    },
    {
        "title": "Air conditioning & Refrigeration",
        "key": "92",
        "tooltip": "ethiopia-tender-air-conditioning-refrigeration"
    },
    {
        "title": "Art",
        "key": "144",
        "tooltip": "ethiopia-tender-art",
        "isFolder": true,
        "children": [
            {
                "title": " Audio Visual, Photography & Filming Service & Equipment",
                "key": "41",
                "tooltip": "ethiopia-tender--audio-visual-photography-filming-service-equipment"
            },
            {
                "title": "Fine Art, Antiques, Crafts",
                "key": "110",
                "tooltip": "ethiopia-tender-fine-art-antiques-crafts"
            },
            {
                "title": "Music & Entertainment",
                "key": "86",
                "tooltip": "ethiopia-tender-music-entertainment"
            },
            {
                "title": "Musical Instruments",
                "key": "186",
                "tooltip": "ethiopia-tender-musical-instruments"
            }
        ]
    },
    {
        "title": "Banking Equipment & Services",
        "key": "121",
        "tooltip": "ethiopia-tender-banking-equipment-services"
    },
    {
        "title": "Bid Modification, Amendment & Cancellation",
        "key": "98",
        "tooltip": "ethiopia-tender-bid-modification-amendment-cancellation"
    },
    {
        "title": "Chemicals & Reagents",
        "key": "59",
        "tooltip": "ethiopia-tender-chemicals-reagents"
    },
    {
        "title": "Cleaning & Janitorial Equipment & Service",
        "key": "81",
        "tooltip": "ethiopia-tender-cleaning-janitorial-equipment-service"
    },
    {
        "title": "Construction & Water Works",
        "key": "129",
        "tooltip": "ethiopia-tender-construction-water-works",
        "isFolder": true,
        "children": [
            {
                "title": "Architectural & Construction Design",
                "key": "141",
                "tooltip": "ethiopia-tender-architectural-construction-design"
            },
            {
                "title": "Building & Finishing Materials",
                "key": "80",
                "tooltip": "ethiopia-tender-building-finishing-materials"
            },
            {
                "title": "Building Construction",
                "key": "130",
                "tooltip": "ethiopia-tender-building-construction"
            },
            {
                "title": "Construction Machinery & Equipment",
                "key": "108",
                "tooltip": "ethiopia-tender-construction-machinery-equipment"
            },
            {
                "title": "Contract Administration & Supervision",
                "key": "91",
                "tooltip": "ethiopia-tender-contract-administration-supervision"
            },
            {
                "title": "Finishig Works",
                "key": "191",
                "tooltip": "ethiopia-tender-finishig-works"
            },
            {
                "title": "Irrigation Works",
                "key": "183",
                "tooltip": "ethiopia-tender-irrigation-works"
            },
            {
                "title": "Road & Bridge Construction",
                "key": "131",
                "tooltip": "ethiopia-tender-road-bridge-construction"
            },
            {
                "title": "Sewerage",
                "key": "11",
                "tooltip": "ethiopia-tender-sewerage"
            },
            {
                "title": "Water Construction",
                "key": "182",
                "tooltip": "ethiopia-tender-water-construction"
            },
            {
                "title": "Water Engineering Machinery & Equipment",
                "key": "12",
                "tooltip": "ethiopia-tender-water-engineering-machinery-equipment"
            },
            {
                "title": "Water Pipes & Fittings",
                "key": "42",
                "tooltip": "ethiopia-tender-water-pipes-fittings"
            },
            {
                "title": "Water Proofing Works",
                "key": "192",
                "tooltip": "ethiopia-tender-water-proofing-works"
            },
            {
                "title": "Water System Installation",
                "key": "132",
                "tooltip": "ethiopia-tender-water-system-installation"
            },
            {
                "title": "Water Well & Pool Cleaning",
                "key": "133",
                "tooltip": "ethiopia-tender-water-well-pool-cleaning"
            },
            {
                "title": "Water Well Drilling",
                "key": "13",
                "tooltip": "ethiopia-tender-water-well-drilling"
            }
        ]
    },
    {
        "title": "Consultancy",
        "key": "135",
        "tooltip": "ethiopia-tender-consultancy",
        "isFolder": true,
        "children": [
            {
                "title": "Academic",
                "key": "65",
                "tooltip": "ethiopia-tender-academic"
            },
            {
                "title": "Agricultural",
                "key": "188",
                "tooltip": "ethiopia-tender-agricultural"
            },
            {
                "title": "Engineering",
                "key": "62",
                "tooltip": "ethiopia-tender-engineering"
            },
            {
                "title": "Environmental",
                "key": "195",
                "tooltip": "ethiopia-tender-environmental"
            },
            {
                "title": "Financial",
                "key": "63",
                "tooltip": "ethiopia-tender-financial"
            },
            {
                "title": "Health Related",
                "key": "16",
                "tooltip": "ethiopia-tender-health-related"
            },
            {
                "title": "ICT",
                "key": "15",
                "tooltip": "ethiopia-tender-ict"
            },
            {
                "title": "Legal",
                "key": "64",
                "tooltip": "ethiopia-tender-legal"
            },
            {
                "title": "Organizational",
                "key": "99",
                "tooltip": "ethiopia-tender-organizational"
            },
            {
                "title": "Other",
                "key": "6",
                "tooltip": "ethiopia-tender-other"
            },
            {
                "title": "Social & Public Policy",
                "key": "105",
                "tooltip": "ethiopia-tender-social-public-policy"
            }
        ]
    },
    {
        "title": "Consumable Goods",
        "key": "103",
        "tooltip": "ethiopia-tender-consumable-goods"
    },
    {
        "title": "Courier Services",
        "key": "104",
        "tooltip": "ethiopia-tender-courier-services"
    },
    {
        "title": "Distribution of Different products",
        "key": "2",
        "tooltip": "ethiopia-tender-distribution-of-different-products"
    },
    {
        "title": "Education & Training",
        "key": "136",
        "tooltip": "ethiopia-tender-education-training",
        "isFolder": true,
        "children": [
            {
                "title": "Materials",
                "key": "95",
                "tooltip": "ethiopia-tender-materials"
            },
            {
                "title": "Services",
                "key": "137",
                "tooltip": "ethiopia-tender-services"
            }
        ]
    },
    {
        "title": "Electrical, Electromechanical & Electronics",
        "key": "138",
        "tooltip": "ethiopia-tender-electrical-electromechanical-electronics",
        "isFolder": true,
        "children": [
            {
                "title": "Equipment & Accessories",
                "key": "96",
                "tooltip": "ethiopia-tender-equipment-accessories"
            },
            {
                "title": "Installation, Maintenance & Other Engineering Services",
                "key": "89",
                "tooltip": "ethiopia-tender-installation-maintenance-other-engineering-services"
            },
            {
                "title": "Pumps, Motors & Compressors",
                "key": "171",
                "tooltip": "ethiopia-tender-pumps-motors-compressors"
            }
        ]
    },
    {
        "title": "Energy, Power & Electricity",
        "key": "139",
        "tooltip": "ethiopia-tender-energy-power-electricity",
        "isFolder": true,
        "children": [
            {
                "title": "Coal",
                "key": "169",
                "tooltip": "ethiopia-tender-coal"
            },
            {
                "title": "Firewood",
                "key": "172",
                "tooltip": "ethiopia-tender-firewood"
            },
            {
                "title": "Generators",
                "key": "97",
                "tooltip": "ethiopia-tender-generators"
            },
            {
                "title": "Hydro Power",
                "key": "190",
                "tooltip": "ethiopia-tender-hydro-power"
            },
            {
                "title": "Solar & Photovoltaic",
                "key": "140",
                "tooltip": "ethiopia-tender-solar-photovoltaic"
            }
        ]
    },
    {
        "title": "Food & Beverage",
        "key": "145",
        "tooltip": "ethiopia-tender-food-beverage",
        "isFolder": true,
        "children": [
            {
                "title": "Catering & Cafeteria Services",
                "key": "146",
                "tooltip": "ethiopia-tender-catering-cafeteria-services"
            },
            {
                "title": "Foodstuff & Drinks",
                "key": "85",
                "tooltip": "ethiopia-tender-foodstuff-drinks"
            },
            {
                "title": "Kitchen Equipment",
                "key": "149",
                "tooltip": "ethiopia-tender-kitchen-equipment"
            }
        ]
    },
    {
        "title": "Fuel & Lubricants",
        "key": "117",
        "tooltip": "ethiopia-tender-fuel-lubricants"
    },
    {
        "title": "Furniture & Furnishing",
        "key": "176",
        "tooltip": "ethiopia-tender-furniture-furnishing",
        "isFolder": true,
        "children": [
            {
                "title": "Carpets & Curtains",
                "key": "79",
                "tooltip": "ethiopia-tender-carpets-curtains"
            },
            {
                "title": "Home Appliance & Supplies",
                "key": "68",
                "tooltip": "ethiopia-tender-home-appliance-supplies"
            },
            {
                "title": "House Furniture",
                "key": "73",
                "tooltip": "ethiopia-tender-house-furniture"
            },
            {
                "title": "Office Furniture",
                "key": "74",
                "tooltip": "ethiopia-tender-office-furniture"
            }
        ]
    },
    {
        "title": "Gardening & Landscaping",
        "key": "107",
        "tooltip": "ethiopia-tender-gardening-landscaping"
    },
    {
        "title": "General Service Provision",
        "key": "71",
        "tooltip": "ethiopia-tender-general-service-provision"
    },
    {
        "title": "Geotechnical Investigation & Laboratory Testing",
        "key": "179",
        "tooltip": "ethiopia-tender-geotechnical-investigation-laboratory-testing"
    },
    {
        "title": "Government Treasury Bill",
        "key": "4",
        "tooltip": "ethiopia-tender-government-treasury-bill"
    },
    {
        "title": "Hand Tools & Workshop Equipment",
        "key": "101",
        "tooltip": "ethiopia-tender-hand-tools-workshop-equipment"
    },
    {
        "title": "Health Care, Medical Industry",
        "key": "150",
        "tooltip": "ethiopia-tender-health-care-medical-industry",
        "isFolder": true,
        "children": [
            {
                "title": "Health Related Services & Materials",
                "key": "69",
                "tooltip": "ethiopia-tender-health-related-services-materials"
            },
            {
                "title": "Medical Equipment & Supplies",
                "key": "51",
                "tooltip": "ethiopia-tender-medical-equipment-supplies"
            },
            {
                "title": "Pharmaceutical Products",
                "key": "151",
                "tooltip": "ethiopia-tender-pharmaceutical-products"
            },
            {
                "title": "Veterinary Equipment & Supplies",
                "key": "152",
                "tooltip": "ethiopia-tender-veterinary-equipment-supplies"
            }
        ]
    },
    {
        "title": "Hospitality, Tour & Travel",
        "key": "174",
        "tooltip": "ethiopia-tender-hospitality-tour-travel",
        "isFolder": true,
        "children": [
            {
                "title": "Air Ticket",
                "key": "111",
                "tooltip": "ethiopia-tender-air-ticket"
            },
            {
                "title": "Hotel Service Provision",
                "key": "113",
                "tooltip": "ethiopia-tender-hotel-service-provision"
            },
            {
                "title": "Tour & Travel",
                "key": "20",
                "tooltip": "ethiopia-tender-tour-travel"
            }
        ]
    },
    {
        "title": "Humanitarian Aid Supply, Relief Items",
        "key": "120",
        "tooltip": "ethiopia-tender-humanitarian-aid-supply-relief-items"
    },
    {
        "title": "Industrial Machinery Provision & Installation",
        "key": "56",
        "tooltip": "ethiopia-tender-industrial-machinery-provision-installation"
    },
    {
        "title": "Insurance Service",
        "key": "61",
        "tooltip": "ethiopia-tender-insurance-service"
    },
    {
        "title": "Interior Design Service & Material",
        "key": "76",
        "tooltip": "ethiopia-tender-interior-design-service-material"
    },
    {
        "title": "International Competitive Bidding (ICB)",
        "key": "194",
        "tooltip": "ethiopia-tender-international-competitive-bidding-(icb)"
    },
    {
        "title": "IT & Telecom",
        "key": "134",
        "tooltip": "ethiopia-tender-it-telecom",
        "isFolder": true,
        "children": [
            {
                "title": "Computer & Accessories",
                "key": "82",
                "tooltip": "ethiopia-tender-computer-accessories"
            },
            {
                "title": "Networking Equipment & Installation",
                "key": "29",
                "tooltip": "ethiopia-tender-networking-equipment-installation"
            },
            {
                "title": "Software Provision, Development & Web Design",
                "key": "25",
                "tooltip": "ethiopia-tender-software-provision-development-web-design"
            },
            {
                "title": "Telecommunication  Equipment",
                "key": "24",
                "tooltip": "ethiopia-tender-telecommunication--equipment"
            },
            {
                "title": "Telecommunication Service",
                "key": "175",
                "tooltip": "ethiopia-tender-telecommunication-service"
            }
        ]
    },
    {
        "title": "Laboratory Equipment & Chemicals",
        "key": "58",
        "tooltip": "ethiopia-tender-laboratory-equipment-chemicals"
    },
    {
        "title": "Labour Outsourcing Services",
        "key": "115",
        "tooltip": "ethiopia-tender-labour-outsourcing-services"
    },
    {
        "title": "Land Lease & Real Estate",
        "key": "57",
        "tooltip": "ethiopia-tender-land-lease-real-estate"
    },
    {
        "title": "Maintenance & Repair",
        "key": "153",
        "tooltip": "ethiopia-tender-maintenance-repair",
        "isFolder": true,
        "children": [
            {
                "title": "Furniture",
                "key": "54",
                "tooltip": "ethiopia-tender-furniture"
            },
            {
                "title": "House & Building",
                "key": "55",
                "tooltip": "ethiopia-tender-house-building"
            },
            {
                "title": "Machinery",
                "key": "52",
                "tooltip": "ethiopia-tender-machinery"
            },
            {
                "title": "Office Machines & Computers",
                "key": "154",
                "tooltip": "ethiopia-tender-office-machines-computers"
            },
            {
                "title": "Others",
                "key": "50",
                "tooltip": "ethiopia-tender-others"
            },
            {
                "title": "Vehicle (garage service)",
                "key": "53",
                "tooltip": "ethiopia-tender-vehicle-(garage-service)"
            }
        ]
    },
    {
        "title": "Materials",
        "key": "193",
        "tooltip": "ethiopia-tender-materials"
    },
    {
        "title": "Mechanical",
        "key": "143",
        "tooltip": "ethiopia-tender-mechanical",
        "isFolder": true,
        "children": [
            {
                "title": "Equipment & Accessories",
                "key": "142",
                "tooltip": "ethiopia-tender-equipment-accessories"
            },
            {
                "title": "Installation, Maintenance & Other Engineering Services",
                "key": "88",
                "tooltip": "ethiopia-tender-installation-maintenance-other-engineering-services"
            }
        ]
    },
    {
        "title": "Metal & Metal Working",
        "key": "28",
        "tooltip": "ethiopia-tender-metal-metal-working"
    },
    {
        "title": "Mineral & Natural Resources",
        "key": "49",
        "tooltip": "ethiopia-tender-mineral-natural-resources"
    },
    {
        "title": "Office Supplies & Services",
        "key": "155",
        "tooltip": "ethiopia-tender-office-supplies-services",
        "isFolder": true,
        "children": [
            {
                "title": "Office Machines & Accessories",
                "key": "48",
                "tooltip": "ethiopia-tender-office-machines-accessories"
            },
            {
                "title": "Secretarial Service",
                "key": "157",
                "tooltip": "ethiopia-tender-secretarial-service"
            },
            {
                "title": "Stationery",
                "key": "156",
                "tooltip": "ethiopia-tender-stationery"
            }
        ]
    },
    {
        "title": "Packaging & Labelling",
        "key": "40",
        "tooltip": "ethiopia-tender-packaging-labelling"
    },
    {
        "title": "Personal Care Products & Services",
        "key": "177",
        "tooltip": "ethiopia-tender-personal-care-products-services"
    },
    {
        "title": "Pest Control & Fumigants",
        "key": "158",
        "tooltip": "ethiopia-tender-pest-control-fumigants",
        "isFolder": true,
        "children": [
            {
                "title": "Pest Control & Fumigation",
                "key": "159",
                "tooltip": "ethiopia-tender-pest-control-fumigation"
            },
            {
                "title": "Pesticides, Insecticides & Herbicides",
                "key": "60",
                "tooltip": "ethiopia-tender-pesticides-insecticides-herbicides"
            }
        ]
    },
    {
        "title": "Plastic Raw Materials & Products",
        "key": "160",
        "tooltip": "ethiopia-tender-plastic-raw-materials-products",
        "isFolder": true,
        "children": [
            {
                "title": "Foam Mattress",
                "key": "196",
                "tooltip": "ethiopia-tender-foam-mattress"
            },
            {
                "title": "Plastic Products",
                "key": "197",
                "tooltip": "ethiopia-tender-plastic-products"
            },
            {
                "title": "Plastic Raw Materials",
                "key": "198",
                "tooltip": "ethiopia-tender-plastic-raw-materials"
            }
        ]
    },
    {
        "title": "Printing & Publishing",
        "key": "43",
        "tooltip": "ethiopia-tender-printing-publishing"
    },
    {
        "title": "Privatization",
        "key": "119",
        "tooltip": "ethiopia-tender-privatization"
    },
    {
        "title": "Quality Assurance Services",
        "key": "178",
        "tooltip": "ethiopia-tender-quality-assurance-services"
    },
    {
        "title": "Raw materials",
        "key": "3",
        "tooltip": "ethiopia-tender-raw-materials"
    },
    {
        "title": "Rent",
        "key": "161",
        "tooltip": "ethiopia-tender-rent",
        "isFolder": true,
        "children": [
            {
                "title": "Construction Machinery",
                "key": "34",
                "tooltip": "ethiopia-tender-construction-machinery"
            },
            {
                "title": "House, Building & Warehouse",
                "key": "36",
                "tooltip": "ethiopia-tender-house-building-warehouse"
            },
            {
                "title": "Machinery & Equipment",
                "key": "162",
                "tooltip": "ethiopia-tender-machinery-equipment"
            },
            {
                "title": "Others",
                "key": "33",
                "tooltip": "ethiopia-tender-others"
            },
            {
                "title": "Vehicle",
                "key": "35",
                "tooltip": "ethiopia-tender-vehicle"
            }
        ]
    },
    {
        "title": "Safety & Security",
        "key": "163",
        "tooltip": "ethiopia-tender-safety-security",
        "isFolder": true,
        "children": [
            {
                "title": "Disposal of Hazardous Waste",
                "key": "165",
                "tooltip": "ethiopia-tender-disposal-of-hazardous-waste"
            },
            {
                "title": "Equipment",
                "key": "164",
                "tooltip": "ethiopia-tender-equipment"
            },
            {
                "title": "Service",
                "key": "27",
                "tooltip": "ethiopia-tender-service"
            }
        ]
    },
    {
        "title": "Sales, Disposals & Foreclosure",
        "key": "147",
        "tooltip": "ethiopia-tender-sales-disposals-foreclosure",
        "isFolder": true,
        "children": [
            {
                "title": "Business, Industry & Factory Foreclosure",
                "key": "83",
                "tooltip": "ethiopia-tender-business-industry-factory-foreclosure"
            },
            {
                "title": "Disposal Sale",
                "key": "5",
                "tooltip": "ethiopia-tender-disposal-sale"
            },
            {
                "title": "House & Building Foreclosure",
                "key": "100",
                "tooltip": "ethiopia-tender-house-building-foreclosure"
            },
            {
                "title": "House & Building Sale",
                "key": "32",
                "tooltip": "ethiopia-tender-house-building-sale"
            },
            {
                "title": "Other Sales",
                "key": "30",
                "tooltip": "ethiopia-tender-other-sales"
            },
            {
                "title": "Shares & Others Foreclosure",
                "key": "148",
                "tooltip": "ethiopia-tender-shares-others-foreclosure"
            },
            {
                "title": "Vehicle & Machinery Foreclosure",
                "key": "84",
                "tooltip": "ethiopia-tender-vehicle-machinery-foreclosure"
            },
            {
                "title": "Vehicle & Machinery Sale",
                "key": "31",
                "tooltip": "ethiopia-tender-vehicle-machinery-sale"
            }
        ]
    },
    {
        "title": "Sanitary & Ceramics",
        "key": "187",
        "tooltip": "ethiopia-tender-sanitary-ceramics"
    },
    {
        "title": "Spare Parts & Car Decoration Materials",
        "key": "7",
        "tooltip": "ethiopia-tender-spare-parts-car-decoration-materials"
    },
    {
        "title": "Sport Materials & Equipment",
        "key": "8",
        "tooltip": "ethiopia-tender-sport-materials-equipment"
    },
    {
        "title": "Steel, Metals & Aluminium",
        "key": "166",
        "tooltip": "ethiopia-tender-steel-metals-aluminium",
        "isFolder": true,
        "children": [
            {
                "title": "Aluminium Products",
                "key": "167",
                "tooltip": "ethiopia-tender-aluminium-products"
            },
            {
                "title": "Aluminium Works & Installation",
                "key": "168",
                "tooltip": "ethiopia-tender-aluminium-works-installation"
            },
            {
                "title": "Other Metals",
                "key": "173",
                "tooltip": "ethiopia-tender-other-metals"
            },
            {
                "title": "Steel Raw Materials & Products",
                "key": "26",
                "tooltip": "ethiopia-tender-steel-raw-materials-products"
            }
        ]
    },
    {
        "title": "Tender Award Notice",
        "key": "118",
        "tooltip": "ethiopia-tender-tender-award-notice"
    },
    {
        "title": "Tents & Camping Equipment",
        "key": "114",
        "tooltip": "ethiopia-tender-tents-camping-equipment"
    },
    {
        "title": "Test & Measurement Tools",
        "key": "180",
        "tooltip": "ethiopia-tender-test-measurement-tools"
    },
    {
        "title": "Textile, Garment & Leather",
        "key": "22",
        "tooltip": "ethiopia-tender-textile-garment-leather",
        "isFolder": true,
        "children": [
            {
                "title": "Shoes & Other Leather Products",
                "key": "184",
                "tooltip": "ethiopia-tender-shoes-other-leather-products"
            },
            {
                "title": "Textile, Garments & Uniforms",
                "key": "185",
                "tooltip": "ethiopia-tender-textile-garments-uniforms"
            }
        ]
    },
    {
        "title": "Translation, Editing & Writing",
        "key": "9",
        "tooltip": "ethiopia-tender-translation-editing-writing"
    },
    {
        "title": "Tri Wheeler, Motorcycles & Bicycles Purchase",
        "key": "17",
        "tooltip": "ethiopia-tender-tri-wheeler-motorcycles-bicycles-purchase"
    },
    {
        "title": "Vehicles Purchase",
        "key": "18",
        "tooltip": "ethiopia-tender-vehicles-purchase"
    },
    {
        "title": "Warehousing, Transit & Transport Service",
        "key": "170",
        "tooltip": "ethiopia-tender-warehousing-transit-transport-service",
        "isFolder": true,
        "children": [
            {
                "title": "Air Cargo Service",
                "key": "181",
                "tooltip": "ethiopia-tender-air-cargo-service"
            },
            {
                "title": "Freight Transport",
                "key": "21",
                "tooltip": "ethiopia-tender-freight-transport"
            },
            {
                "title": "Transit, Customs Clearing, Packing & Forwarding",
                "key": "19",
                "tooltip": "ethiopia-tender-transit-customs-clearing-packing-forwarding"
            },
            {
                "title": "Transportation Service",
                "key": "189",
                "tooltip": "ethiopia-tender-transportation-service"
            },
            {
                "title": "Warehouse & Storage Service",
                "key": "14",
                "tooltip": "ethiopia-tender-warehouse-storage-service"
            }
        ]
    },
    {
        "title": "Wood & Wood Working",
        "key": "10",
        "tooltip": "ethiopia-tender-wood-wood-working"
    }
]

export default categoryData