import React, {useContext, useState} from "react"
import categoryData from "../data/category";
import {Link} from "react-router-dom";
import Helmet from "react-helmet";
import {CategoryContext} from "./CategoryContext";


const Sidebar = () => {
    let [active, setActive] = useState(false);
    // let {category, setCategory} = useContext(CategoryContext)

    const ToggleMenu = () => {
        setActive(!active)
        active ? document.body.classList.remove('offcanvas') : document.body.classList.add('offcanvas');
    }

    const onCategoryClick = () => {
        // setCategory("lol")
    }
    return (

        // <nav className="navigation">
        //     <ul className="mainmenu">
        //         <li><a href="">Home</a></li>
        //         <li><a href="">About</a></li>
        //         <li><a href="">Products</a>
        //             <ul className="submenu">
        //                 <li><a href="">Tops</a></li>
        //                 <li><a href="">Bottoms</a></li>
        //                 <li><a href="">Footwear</a></li>
        //             </ul>
        //         </li>
        //         <li><a href="">Contact us</a></li>
        //     </ul>
        // </nav>


        <div>
            {/*<aside id="sidebar" className="col-lg-4 col-md-12 col-xs-12">*/}
            {/*    <aside className="col-lg-2 col-md-2 col-sm-3 col-xs-2 col-auto" id="sidebar">*/}
            <a className={active ? "js-fitlfit-nav-toggle fitlfit-nav-toggle active" : "js-fitlfit-nav-toggle fitlfit-nav-toggle"}
               onClick={ToggleMenu}><i></i> {active ? "" : "Menu"}<br/></a>
            <aside id="fitlfit-aside" role="complementary" className="js-fullheight">
                <div className="widget">
                    <h5 className="widget-title">Categories</h5>
                    <div className="widget-categories widget-box sidebar-heading">
                        <ul className="mainmenu">
                            {
                                categoryData.map(
                                    category => {
                                        return (
                                            <li className="category-list" key={category.key}>
                                                {/*<a
                                                                                                data-toggle="collapse"
                                                                                                aria-expanded="false"
                                                                                                className="dropdown-toggle">*/}
                                                <Link to={{pathname: "/tenders/" + category.tooltip}}>
                                                    {category.title}</Link>
                                                <ul className="submenu">
                                                    {
                                                        category.children ?
                                                            category.children.map(child => {
                                                                    return (
                                                                        <li key={child.key}>
                                                                            <Link
                                                                                to={{pathname: "/tenders/" + child.tooltip}} onClick={onCategoryClick}>
                                                                                {child.title}</Link>
                                                                            <Helmet>
                                                                                <title>{ child.title }</title>
                                                                                <meta name="description" content={child.title} />
                                                                            </Helmet>
                                                                        </li>
                                                                    )
                                                                }
                                                            ) : ''
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                            }
                        </ul>
                    </div>
                </div>

                {/*<div className="widget">
                    <h5 className="widget-title">Filter by location</h5>
                    <div className="widget-categories widget-box">

                        <div className="col-lg-5 col-md-5 col-xs-12">
                        <div className="form-group">
                            <div className="search-category-container">
                                <label className="styled-select">
                                    <select name="TenderRegionId" id="TenderRegionId">
                                        <option value="">-Location-</option>
                                        <option value="1">Addis Ababa</option>
                                        <option value="2">Afar</option>
                                        <option value="3">Amhara</option>
                                        <option value="4">Beneshangul Gumuz</option>
                                        <option value="5">Dire Dawa</option>
                                        <option value="6">Gambela</option>
                                        <option value="7">Hareri</option>
                                        <option value="8">Oromia</option>
                                        <option value="9">SNNPR</option>
                                        <option value="10">Somali</option>
                                        <option value="11">Tigray</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>*/}


            </aside>

        </div>
    )
}

export {Sidebar}