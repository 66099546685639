import React from 'react';
import ReactDOM from 'react-dom';

// Importing the Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from "./Routes";
// import {Router} from "react-router-dom"
import { BrowserRouter as Router } from 'react-router-dom';

// import createHistory from 'history/createBrowserHistory'
import * as serviceWorker from "./serviceWorker"

// const history = createHistory()

ReactDOM.render(
    <Router>
        <Routes/>
    </Router>,
    document.getElementById('root'));
serviceWorker.unregister()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
