import React, {useEffect} from 'react'
import api from './api'
import axios from "axios";

const TenderDetailsPage = ({tender}) => {

    return (
        <div>
            <p>{tender.id}</p>
            <p>{tender.tenderTitle}</p>
            {/*<p>{tender.bidInfo.bidClosingDate}</p>*/}
            <div dangerouslySetInnerHTML={{__html: tender.tenderDescription}}></div>
            <p>{tender.companyName}</p>
            <p>{tender.companyLogo}</p>
            {/*<p>{tenderData.companyAddress.address}</p>*/}
            <p>{tender.categoryList}</p>
            <p>{tender.tenderSourceUrl}</p>
        </div>
    )
}

const IndexPage = () => {


    // Create state variables
    let [tender, setTender] = React.useState({})

    useEffect(() => {
        axios.get('http://localhost:8081/api/get/e8b71cd9')
            .then(
                result => {
                    console.log(result);
                    setTender(result.data)
                }
            ).catch(error => {
                    console.log(error)
        })

    }, []);

    // useEffect(() => {
    //     // fetchData()
    //     tendersAPI.get("/")
    //         .then(
    //             res => res
    //         ).then(
    //             res => {
    //                 console.log(res.data);
    //                 setTenders(res.data.json());
    //             }
    //     ).catch(e => {
    //         console.log(e)
    //     })
    // });


    return (
        <div>

            <div>
                {

                                <TenderDetailsPage tender={tender} />


                }
            </div>
        </div>
    )
}

export default IndexPage