import React, {useEffect} from "react"
import axios from "axios";
import {Link} from "react-router-dom";

const tendersAPI = axios.create({
    baseURL: process.env.REACT_APP_TENDER_API,
    headers: {},
});

export const TenderHighlights = () => {


    // Create state variables
    let [tenders, setTenders] = React.useState([])

    useEffect(() => {
        tendersAPI.get("/")
            .then(
                result => {
                    setTenders(result.data)
                }
            ).catch(error => {
            console.log(error)
        })

    }, []);
    return (
        <>
            <div className="stock-container">
                {
                    tenders.map(
                        (tender, index) => {
                            return (
                                <TenderHighlight key={index} tender={tender}/>
                            )
                        }
                    )
                }
            </div>
        </>
    );
};

const TenderHighlight = ({tender}) => {
    if (!tender) return <div/>;
    return (
        <div className="col-lg-12 col-md-12 col-xs-12">
            <Link to={{
                pathname: "/bid/" + tender.id
            }}>
                <div className="job-featured">
                    <div className="content">
                        <a>{tender.tenderTitle}</a>
                        <div className="tags">
                            <span><i className="lni-map-marker"></i> {tender.bidClosingDate}</span>
                            {/*<span><i className="lni-user"></i>{tender.bidInfo.bidOpeningDate}</span>*/}
                            <span className="full-time">Open</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
};

export default TenderHighlights