import React, {useEffect, useState} from "react"
import {useParams} from "react-router";
import Axios from "axios";
import {Footer2} from "./footer2";

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

const SingleTenderDetails2 = () => {
    let {id} = useParams();
    let [tender, setTender] = React.useState({});
    let [address, setAddress] = React.useState({});
    let [bidInfo, setBidInfo] = React.useState({});
    let [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        Axios.get(process.env.REACT_APP_SINGLE_TENDER_API + id)
            .then(
                result => {
                    setTender(result.data)
                    setAddress(result.data.companyAddress)
                    setBidInfo(result.data.bidInfo)
                    setLoading(false)
                }
            ).catch(error => {
            console.log(error)
        })
    }, []);
    return (
        <div id="fitlfit-page">
            {/*<ScrollToTopOnMount/>*/}
            <div id="fitlfit-main">
                <section className="ftco-section ftco-no-pt ftco-no-pb">
                    <div className="container">
                        <div className="row d-flex">
                            {loading ?
                                <div className="col-lg-8 px-md-5 py-5 row pt-md-4">
                                    <div className="text-center spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <div className="col-lg-8 px-md-5 py-5">
                                    <div className="row pt-md-4">
                                        <div className="content">
                                            <h3 className="mb-3">{tender.tenderTitle}</h3>
                                            <p className="brand">{tender.companyName}</p>
                                            <div className="about-author d-flex p-4 bg-light">
                                                <div className="desc">
                                                    <p><i className="icon-lock"></i> {bidInfo.bidClosingDate}</p>
                                                    <p><i className="icon-lock_open"></i>{bidInfo.bidOpeningDate}</p>
                                                    {/*<p><i className="icon-public"></i>{bidInfo.postedDate}</p>*/}
                                                    <p><i className="icon-public"></i> {bidInfo.publishedOn}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{__html: tender.tenderDescription}}></div>

                                        {/*<div className="tag-widget post-tag-container mb-5 mt-5">*/}
                                        {/*    <div className="tagcloud">*/}
                                        {/*        {*/}
                                        {/*            categories.map(*/}
                                        {/*                (category, index) => {*/}
                                        {/*                    return <a href="#" className="tag-cloud-link"*/}
                                        {/*                              key={index}>{category}</a>*/}
                                        {/*                }*/}
                                        {/*            )*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="about-author d-flex p-4 bg-light">
                                            <div className="bio mr-5">
                                                <img src=""
                                                     className="img-fluid mb-4" alt=""/>
                                            </div>
                                            <div className="desc">
                                                <h3>{tender.companyName}</h3>
                                                {address.phone ?
                                                    <p><i className="icon-phone"></i> {address.phone} </p> :
                                                    <p></p>}
                                                {address.phone2 ?
                                                    <p><i className="icon-phone"></i> {address.phone2} </p> :
                                                    <p></p>}
                                                {address.phone3 ?
                                                    <p><i className="icon-phone"></i> {address.phone3} </p> :
                                                    <p></p>}
                                                {address.address ?
                                                    <p><i className="icon-location_city"></i> {address.address} </p> :
                                                    <p></p>}
                                                {address.email ?
                                                    <p><i className="icon-contact_mail"></i>: {address.email} </p> :
                                                    <p></p>}
                                                {address.website ?
                                                    <p><i className="icon-web"></i>: {address.website} </p> :
                                                    <p></p>}
                                                {address.fax ? <p><i className="icon-fax"></i>: {address.fax}  </p> :
                                                    <p></p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Footer2/>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export {SingleTenderDetails2}