import React, {useContext} from "react";
import TenderHighlights from "./TenderHighlight"
import TenderHighlights2 from "./TenderHighlight2";
import {Footer2} from "./footer2";
import {Route, Switch} from "react-router-dom";
// import {CategoryContext} from "./CategoryContext";

const Homepage2 = () => {
    // const [category, setCategory] = useContext(CategoryContext)
    return (
        <div id="fitlfit-page">
            <div id="fitlfit-main">
                <section className="ftco-section ftco-no-pt ftco-no-pb">
                    <div className="container">
                        <div className="row d-flex">
                            <div className="col-xl-8 py-5 px-md-5">
                                <div className="row pt-md-4">

                                   {/* <div>
                                        {category}
                                    </div>*/}

                                    <TenderHighlights2 />
                                </div>

                            </div>
                            <Footer2/>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export {Homepage2}